.noMessages {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.dateHeader {
  display: inline-block;
  background: #e2e2ed;
  mix-blend-mode: normal;
  border-radius: 20px;

  margin-top: 28px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 3px;
  padding-top: 6px;
  font-size: 12px;
  line-height: 15px;
}
