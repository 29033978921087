.photoImg {
  height: 400px;
  -moz-transition: 1s ease;
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  transition: 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoWidth {
  width: 400px;
  height: 400px;
  -moz-transition: 1s ease;
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  transition: 1s ease;
}
