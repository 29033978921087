audio {
  width: 100%;
  min-width: 300px;
}

.msgText {
  text-align: left;
  font-size: 17px;
  line-height: 135%;
  color: #2f374d;
}

.msgName {
  text-align: left;
  font-weight: bold;
  font-size: 17px;
  line-height: 135%;
  /* or 23px */

  letter-spacing: 0.3px;

  color: #2f374d;
}

.bottomLine {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #e0e0e0;
}

.showMore {
  display: flex;
  font-size: 12px;
  /* identical to box height, or 125% */

  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #7674e2;
  margin-top: 16px;
  cursor: pointer;
}
