#menuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 20px;
  font-size: 4em;
  font-weight: 800;
  z-index: 111;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
  margin-left: 20px;
  margin-right: 20px;
}

#leftMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
  top: 0;
  overflow: auto;
  scrollbar-width: none;
  background-color: #323163;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 100;
  opacity: 0;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  position: fixed;
}

#leftMenuFixed {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0px;
  overflow: auto;
  scrollbar-width: none;
  background-color: #323163;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 11;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

.menuFill {
  position: fixed;
  z-index: 99;
  background-color: #000000;
  opacity: 0.4;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#rightMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
  top: 0;
  overflow: auto;
  scrollbar-width: none;
  background-color: #f9f9fa;
  position: absolute;
  z-index: 100;
  opacity: 0;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  position: fixed;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.13);
}

#leftMenuexit {
  position: absolute;
  right: 10px;
  padding: 0px;
  top: -10px;
  font-size: 4em;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

#rightMenuexit {
  position: absolute;
  left: 10px;
  padding-left: 20px;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  top: -10px;
  font-size: 4em;
  color: #2f374d;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
}

#leftMenu.visible {
  opacity: 1;
  left: 0;
}

#rightMenu.visible {
  opacity: 1;
  right: 0;
}
