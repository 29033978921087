*:focus {
  outline: none;
}

#textInput::placeholder {
  opacity: 0.5;
  font-size: 17px;
  color: #2f374d;
}

.composer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f6;
}

.composerBar {
  display: flex;
  flex-direction: row;
  padding-left: 14px;
  padding-right: 14px;
}

.composerButton {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputArea {
  display: flex;
  flex: 1;
  background-color: white;
  height: 42px;
  border-radius: 23px;
  padding-left: 23px;
  padding-right: 9px;
}
.emptyWidth {
  width: 88px;
}

.micContainer {
  margin-left: 14px;
  margin-right: 12px;
  margin-bottom: 12px;
  position: fixed;
  right: 0;
  bottom: 0;
}

.micButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: #ee5656;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
}

.textInput {
  width: 100%;
  min-width: 20%;
  display: flex;
  flex: 1;
  border: none;
  opacity: 1;
  font-size: 17px;
  /* identical to box height, or 88% */

  color: #2f374d;
}
