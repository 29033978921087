@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Regular.eot');
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
    url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('ProximaNova-Regular.woff') format('woff'),
    url('ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Bold.eot');
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
    url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('ProximaNova-Bold.woff') format('woff'),
    url('ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Extrabld.eot');
  src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
    url('ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
    url('ProximaNova-Extrabld.woff') format('woff'),
    url('ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Light.eot');
  src: local('Proxima Nova Light'), local('ProximaNova-Light'),
    url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
    url('ProximaNova-Light.woff') format('woff'),
    url('ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('ProximaNova-Semibold.eot');
  src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
    url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
    url('ProximaNova-Semibold.woff') format('woff'),
    url('ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
