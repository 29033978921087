.roomsSettingsContainer {
  height: 100%;
  overflow: auto;
}

.roomSettingsHeaderContainer {
  display: flex;
  height: 75px;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.roomSettingsUsers {
  padding-bottom: 15px;
}

.roomSettingsHeader {
  font-weight: 800;
  font-size: 20px;

  text-align: center;

  color: #2f374d;
}

.roomSettingsName {
  font-size: 14px;

  text-align: center;

  color: #2f374d;
}

.membersCount {
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  letter-spacing: 0.25px;
  text-transform: uppercase;

  color: #677086;
}
