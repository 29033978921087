.roomListItem {
  height: 66px;

  border-radius: 8px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.currentListItem {
  background-color: #7674e280;
  cursor: pointer;
}

.itemCircle {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: violet;
  margin: 8px;
  align-items: center;
  justify-content: center;
}

.itemLetter {
  font-weight: 800;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
}

.itemDescription {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.itemName {
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  text-align: left;
}

.itemTime {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff80;
}
