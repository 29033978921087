.Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f6;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Scroll {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  height: 100%;
  margin-top: 65px;
  margin-bottom: 62px;
}

.MessagesContainer {
  display: block;
  width: 100%;
  height: 100%;
  /*display: flex;
  flex: 1;
  flex-direction: column;*/
}

.Header {
  position: fixed;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  background-color: #7674e2;
  z-index: 10;
}

.roomHeader {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65px;
}

.roomName {
  display: flex;
  flex: 1;
  margin-top: 17px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: white;
}

.roomMembers {
  flex: 1;
  font-size: 14px;
  margin-bottom: 13px;
  /* identical to box height, or 157% */

  text-align: center;

  color: #ffffff;

  opacity: 0.7;
}

.menuHeader {
  height: 75px;
  font-weight: 800;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height, or 123% */
  display: flex;
  align-items: flex-end;
  align-content: flex-start;
  color: #ffffff;
  padding-left: 20px;
}

.logOutButton {
  height: 75px;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height, or 123% */
  display: flex;
  align-items: center;
  align-content: flex-start;
  color: #ffffff;
  padding-left: 20px;
  cursor: pointer;
}

.menuBottomButton {
  display: flex;
  align-items: center;
  align-content: flex-start;
  height: 58px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.Footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 62px;
  background-color: transparent;
  z-index: 10;
}
