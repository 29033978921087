.roomsUserItem {
  display: flex;
  flex-direction: row;
  height: 60px;
  flex: 1;
  background-color: #fefefe;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  box-shadow: 0px 2px 10px rgba(231, 231, 235);
}

.roomsUserName {
  font-size: 16px;
  line-height: 20px;

  color: #2f374d;
}

.roomsUserType {
  font-size: 15px;

  color: #acb4be;
  margin-left: 7px;
  margin-right: 7px;
}

.roomsUserType_patient {
  font-weight: bold;
  font-size: 10px;

  color: #ffffff;
  background: #7674e2;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 7px;
  margin-right: 7px;
}
