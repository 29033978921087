input.slider[type='range'] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input.slider[type='range']:focus {
  outline: none;
}
input.slider[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /*background: #7674e2;*/
  border-radius: 25px;
}
input.slider[type='range']::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #7674e2;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
  border: 0px solid transparent;
}
/*
input.slider[type='range']:focus::-webkit-slider-runnable-track {
  background: #7674e2;
}
*/
input.slider[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /*animate: 0.2s;*/
  /*background: #7674e2;*/
  border-radius: 25px;
}
input.slider[type='range']::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #7674e2;
  cursor: pointer;
}
input.slider[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /*animate: 0.2s;*/
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input.slider[type='range']::-ms-fill-lower {
  /*background: #7674e2;*/
  border-radius: 50px;
}
input.slider[type='range']::-ms-fill-upper {
  /*background: #7674e2;*/
  border-radius: 50px;
}
input.slider[type='range']::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #7674e2;
  cursor: pointer;
}
/*
input.slider[type='range']:focus::-ms-fill-lower {
  background: #7674e2;
}
input.slider[type='range']:focus::-ms-fill-upper {
  background: #7674e2;
}
*/
