body {
  background-color: #7674e2;
  -webkit-font-smoothing: antialiased;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

/*
@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}
*/

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent;
  background-color: #f0f0f6;
  box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  -webkit-text-fill-color: #7674e2 !important;
  -webkit-text-size-adjust: 0% !important;
  font-size: 33px !important;
}

input:-webkit-autofill::first-line {
  font-family: 'Proxima Nova' !important;
  font-size: 33px !important;
  border: none !important;
  font-weight: 800 !important;
  background-color: #f0f0f6;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.App {
  text-align: center;
}

.LogoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 80px;
}

h1 {
  color: white;
}

.App-header {
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.wordPad {
  margin-right: 0.15em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  color: #2f374d;
}

.punctuationPad {
  margin-right: 0.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */
}

.pointer {
  cursor: pointer;
}
