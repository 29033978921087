.msgOuter {
  display: flex;
  flex-direction: column;
  /*
  margin-left: 30px;
  margin-right: 30px;
  */
  margin-top: 8px;
}

.msgBubble {
  max-width: 600px;
  background-color: transparent;
  border-radius: 24px;

  -moz-transition: max-height 1s ease;
  -webkit-transition: max-height 1s ease;
  -o-transition: max-height 1s ease;
  transition: max-height 1s ease;
}

.MsgPaddingHor {
  padding-left: 16px;
  padding-right: 16px;
}

.MsgPaddingVer {
  padding-top: 13px;
  padding-bottom: 13px;
}

.MsgPaddingBottom {
  padding-bottom: 13px;
}

.MsgPaddingTop {
  padding-top: 13px;
}

.MsgWithRadius {
  border-radius: 24px;
  background-color: white;
}

/*
.MessageB {
  
  background-color: white;
  border-radius: 22px;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 14px;
  padding-bottom: 14px;
  
}
*/

.colorPurple {
  background-color: #7674e2;
}

.colorWhite {
  background-color: #ffffff;
}

.inv {
  display: flex;
  flex-direction: row-reverse !important;
}

.messageHeader {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 7px;
  /*
  padding-left: 19px;
  padding-right: 19px;
  */
}

.userName {
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 121% */

  color: #7674e2;
  font-weight: bold;
}

.msgDate {
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 125% */
  color: #2f374d80;
}

.alignLeft {
  align-items: flex-start;
}

.alignRight {
  align-items: flex-end;
}
