.SignInContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f0f6;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: flex-start;
  align-items: center;
}

.logo {
  margin-top: 30px;
}
.pageHeader {
  display: flex;
  margin-top: 35px;
  margin-bottom: 24px;
  font-size: 33px;
  line-height: 40px;
  /* identical to box height, or 121% */

  color: #333333;
  font-weight: 800;
}

.pageInner {
  display: flex;
  flex-direction: column;
  max-width: 295px;
  margin-left: 40px;
  margin-right: 40px;
}

.inputDescription {
  font-size: 18px;
  line-height: 22px;
  /* or 122% */

  color: #2e364a;
  text-align: start;
}

.inputForm {
  display: flex;
  flex-direction: column;
}

.formDescriptionHeader {
  align-self: flex-start;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  /* or 137% */

  color: #979dad;
}
.formDescription {
  align-self: flex-start;
  text-align: left;
  font-size: 13px;
  font-weight: normal;
  line-height: 17px;
  /* or 137% */

  color: #979dad;
}

.numInput {
  font-family: 'Proxima Nova';
  background-color: transparent;
  border: none;
  font-weight: 800;
  color: #7674e2;
  font-size: 33px;
  line-height: 45px;
  margin-top: 22px;
  padding: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
}

.fullWidth {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
.numInput::-webkit-outer-spin-button,
.numInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numInput[type='number'] {
  -moz-appearance: textfield;
}

.sendButton {
  background-color: transparent;
  border: none;
  height: 61px;
  background-color: #7674e2;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  /* identical to box height, or 122% */

  text-align: center;

  color: #ffffff;

  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
}

.numInput::placeholder {
  color: #c0c0cc;
  font-size: 33px;
  line-height: 45px;
  font-weight: 800;
}

.aLink {
  color: #7674e2;
  margin-left: 5px;
  margin-right: 5px;
}

.loginErrorText {
  align-self: flex-start;
  text-align: left;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */

  /* Red */

  color: #ee5656;
}
