#wrapper {
  width: 100%;
  min-width: 300px;
}

.bottomLine {
  border-bottom: 1px solid #e0e0e0;
}

.player {
  height: 110px;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.playStopCont {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.playerButton {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #7674e2;
}

.downloadCont {
  height: 72px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadButton {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #f0f0f6;
}

.slidecontainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.player_label {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #23232380;
}

.player_btn {
  cursor: pointer;
}
